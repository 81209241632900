import React from "react";

const CarouselRight = () => {
	return (
		<span
			className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-white/50">
			<svg className="w-4 h-4 text-white" fill="none" stroke="currentColor"
				viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round"
					strokeLinejoin="round" strokeWidth="2"
					d="M9 5l7 7-7 7"></path></svg>
			<span className="hidden">Previous</span>
		</span>
	);
};

export default CarouselRight;

