import React from "react";

const Section = ({ heading, children, returnTypes = [] }) => {
	return (
		<div className="w-full pb-8 px-8 md:px-16 lg:px-28">
			<h2 className="text-fireWatchBlue-100 text-2xl py-1">
				<span className="text-deepOceanPurple hidden md:inline-block">func&nbsp;</span>
				<span className="text-deepOceanBlue inline-block md:hidden">.</span>
				<span className="text-deepOceanBlue">{heading}</span>()&nbsp;

				{returnTypes.length > 1 && (
					<span className="text-fireWatchBlue-100 hidden md:inline-block">(</span>
				)}

				{returnTypes.map((returnType, index) => {
					if (returnType.metaType === "array") {
						return (
							<span key={index}>
								{index !== 0 && returnTypes.length > 1 && <span className="hidden md:inline-block">,&nbsp;</span>}
								<span className="text-fireWatchBlue-100 hidden md:inline-block">[]</span>
								<span className="text-deepOceanGreen hidden md:inline-block">{returnType.type}</span>
							</span>
						);
					}

					return (
						<span key={index}>
							{index !== 0 && returnTypes.length > 1 && <span className="hidden md:inline-block">,&nbsp;</span>}
							<span className="text-deepOceanGreen hidden md:inline-block">{returnType.type}</span>
						</span>
					);
				})}

				{returnTypes.length > 1 && (
					<span className="text-fireWatchBlue-100 hidden md:inline-block">)</span>
				)}
				<span className="text-fireWatchBlue-100 hidden md:inline-block">&nbsp;&#123;</span>
			</h2>
			<div className="md:pl-8">
				{children}
			</div>
			<h2 className="text-fireWatchBlue-100 text-2xl py-1 hidden md:inline-block">
				&#125;
			</h2>
		</div>
	);
};

export default Section;
