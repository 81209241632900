import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Carousel } from "flowbite-react";
import CarouselLeft from "./CarouselLeft";
import CarouselRight from "./CarouselRight";
import HoverableVideo from "./HoverableVideo";

function getRandomInterval() {
	let low = 4000;
	let high = 8000;

	return Math.floor(Math.random() * (high - low + 1)) + low;
}

const Project = ({ name, shortDescription, projectId, useGifInitialImage = false, imageUrls = [] }) => {
	const [isOpen, setIsOpen] = useState(false);

	const closeModal = () => {
		setIsOpen(false);
	};

	const openModal = () => {
		// setIsOpen(true);
	};

	const slideInterval = getRandomInterval();

	return (
		<>
			<div className="p-1 backdrop-blur-[3px] rounded-xl border-[3px] border-fireWatchBlue-400 m-2 flex-none w-fit sm:inline sm:h-auto group
				hover:scale-[1.03] pointer-events-none transition ease-in-out duration-200">
				<div className="p-3 rounded-lg text-white">
					<div className="h-64 pointer-events-auto">
						<Carousel leftControl={CarouselLeft()} rightControl={CarouselRight()} slide={true}
							slideInterval={slideInterval} pauseOnHover={true} >
							{imageUrls.map((imageUrl, index) => {
								if (useGifInitialImage === true && index === 0)
									return <HoverableVideo basePath={`images/projects/${projectId}/${imageUrl}`} imageUrl={imageUrl}/>;

								return <img key={imageUrl} src={`images/projects/${projectId}/${imageUrl}`} alt={`${projectId} ${imageUrl}`}/>;
							})}
						</Carousel>
					</div>
					<div onClick={openModal} className="pointer-events-auto cursor-default group">
						<h2 className="text-lg text-deepOceanOrange font-medium title-font my-1">{name}</h2>
						<p className="leading-relaxed text-base text-justify">{shortDescription}</p>
					</div>
				</div>
			</div>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as="div" className="relative z-20" onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0">
						<div className="fixed inset-0 bg-black bg-opacity-25" />
					</Transition.Child>
					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95">
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden
								bg-fireWatchBlue-900/90 backdrop-blur-[3px] rounded-xl border-[3px] border-fireWatchBlue-400 m-2 p-6 text-left align-middle transition-all">
									<Dialog.Title as="h3" className="text-lg font-medium leading-6">
										Payment successful
									</Dialog.Title>
									<div className="mt-2">
										<p className="text-sm">
											Your payment has been successfully submitted. We’ve sent
											you an email with all of the details of your order.
										</p>
									</div>
									<div className="mt-4">
										<button
											type="button"
											className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium
												 text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
											onClick={closeModal}>
											Got it, thanks!
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
};

export default Project;
