import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
// import Football from "./pages/Football";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<Router>
			<Routes>
				{/*<Route exact path="/football" element={<Football/>}/>*/}
				<Route exact path="/" element={<App/>}/>
				{/* Redirect all other paths to App */}
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		</Router>
	</React.StrictMode>
);
