import React from "react";

const Tooltip = ({ tooltipText, bottomOffset }) => {
	return (
		<div className={`absolute bottom-${bottomOffset} -left-3 z-40 group-hover:block hidden w-max p-2 backdrop-blur-[3px] rounded-xl bg-fireWatchBlue-400 m-2`}>
			<p className="select-none leading-none text-sm">{tooltipText}</p>
		</div>
	);
};

export default Tooltip;
