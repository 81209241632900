import React from "react";
import Tooltip from "./Tooltip";

const SkillIcon = ({ children, tooltipText }) => {
	return (
		<div className="relative group">
			<svg className="h-12 w-12 m-0.5 justify-self-center" viewBox="0 0 128 128">
				{children}
			</svg>
			<Tooltip bottomOffset="12" tooltipText={tooltipText}/>
		</div>
	);
};

export default SkillIcon;
