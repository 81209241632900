import React, { useRef } from "react";

const HoverableVideo = ({ basePath, imageUrl }) => {
	const myRef = useRef(null);
	const handlePlayVideo = () => {
		myRef.current.play();
	};
	const handlePauseVideo = () => {
		myRef.current.pause();
	};

	return (
		<video src={basePath} loop preload="auto" ref={myRef} muted="muted" key={imageUrl} className="pointer-events-auto cursor-default"
			onMouseEnter={() => handlePlayVideo()}
			onMouseLeave={() => handlePauseVideo()}>
		</video>
	);
};

export default HoverableVideo;
